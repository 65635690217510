import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useAsyncState } from 'hooks';

const Icon = ({ isOpen }) => {
  const [open, setOpen] = useAsyncState(isOpen);

  useEffect(() => {
    if (isOpen !== open) {
      setTimeout(() => setOpen(isOpen), 150);
    }
  }, [isOpen, open, setOpen]);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 53 53">
      {open ? (
        <g fill="var(--color-green-active)" fillRule="nonzero">
          <path d="M8.676 7.055l36.77 36.77-2.122 2.12-36.77-36.77z" />
          <path d="M43.324 7.055l-36.77 36.77 2.122 2.12 36.77-36.77z" />
        </g>
      ) : (
        <g fill="var(--color-green-active)" fillRule="nonzero">
          <path d="M1 15h52v3H1zM1 35h52v3H1z" />
        </g>
      )}
    </svg>
  );
};

Icon.defaultProps = { isOpen: false };
Icon.propTypes = { isOpen: PropTypes.bool };

export default Icon;
