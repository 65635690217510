import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { PreImage, Picture, Image } from './styles';

const DynamicImage = ({ webpSrc, jpgSrc, pngSrc, sizes, preloadImg, src, alt, isHidden, className }) => {
  const [isLoaded, setLoaded] = useState(!preloadImg);
  const onLoad = useCallback(() => setLoaded(true), []);

  return (
    <>
      {preloadImg && !isLoaded && <PreImage src={preloadImg} className={className} alt={alt} />}
      {!isHidden && (
        <Picture $isLoaded={isLoaded}>
          {webpSrc && <source type="image/webp" srcSet={webpSrc} sizes={sizes} />}
          {jpgSrc && <source type="image/jpeg" srcSet={jpgSrc} sizes={sizes} />}
          {pngSrc && <source type="image/png" srcSet={pngSrc} sizes={sizes} />}
          <Image src={src} className={className} alt={alt} onLoad={preloadImg ? onLoad : undefined} $isLoaded={isLoaded} />
        </Picture>
      )}
    </>
  );
};

DynamicImage.defaultProps = {
  webpSrc: null,
  jpgSrc: null,
  pngSrc: null,
  sizes: undefined,
  preloadImg: null,
  isHidden: false,
  className: null,
};
DynamicImage.propTypes = {
  webpSrc: PropTypes.string,
  jpgSrc: PropTypes.string,
  pngSrc: PropTypes.string,
  sizes: PropTypes.string,
  preloadImg: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  isHidden: PropTypes.bool,
  className: PropTypes.string,
};

export default DynamicImage;
