import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Button, Text, IconWrap, Icon } from './styles';

const BackButton = ({ name, onClick, className }) => {
  const { t } = useTranslation('app');
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <Button type="button" onClick={onClick || goBack} aria-label={t('Back')} className={className}>
      <Text>{name || t('Back')}</Text>
      <IconWrap>
        <Icon />
      </IconWrap>
    </Button>
  );
};

BackButton.defaultProps = {
  name: null,
  onClick: null,
  className: null,
};
BackButton.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default BackButton;
