import React from 'react';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useIsLoggedIn } from 'store/user';
import ExternalHeader from '../ExternalHeader';
import InternalHeader from '../InternalHeader';
import Footer from '../Footer';

import { GlobalStyles, Main, Button, Title, MarginBox } from './styles';

const ExternalLayout = ({ title }) => {
  const isLoggedIn = useIsLoggedIn();

  return (
    <>
      <GlobalStyles />
      {isLoggedIn ? <InternalHeader /> : <ExternalHeader />}
      <Main $isLoggedIn={isLoggedIn}>
        <Button />
        {title && (
          <>
            <Title>{title}</Title>
            <MarginBox />
          </>
        )}
        <Outlet />
      </Main>
      <Footer />
    </>
  );
};

ExternalLayout.defaultProps = { title: null };
ExternalLayout.propTypes = { title: PropTypes.string };

export default ExternalLayout;
